'use client';

import Container from '@/components/Layout/Container';
import Lottie from 'lottie-react';
import React from 'react';
import FourOhFourAnimation from 'public/assets/animations/404-animation-2.json';
import Button from '@/components/general/Button';
import Link from 'next/link';
import i18next from 'i18n';

const FourOhFour = () => {
    return (
        <Container>
            <div className="flex flex-col justify-center items-center py-[150px] space-y-[0]">
                <h1 className="font-heading text-[58px] font-[400] mb-[-150px] z-10 text-main">{i18next.t('general.page-not-found')}</h1>
                <div className="min-h-[500px] w-full flex flex-col justify-center">
                    <Lottie animationData={FourOhFourAnimation} loop />
                </div>
                <Link href="/">
                    <Button className="mt-[-100px]">{i18next.t('button-text.home')}</Button>
                </Link>
            </div>
        </Container>
    );
};

export default FourOhFour;
